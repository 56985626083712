import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';
import './App.css';

const App = () => {
  const svgRef = useRef();

  useEffect(() => {
    // Updated data with additional fields
    const data = {
      name: "Bob and Henrietta Hinzman",
      birthday: "1940-01-01",
      location: "New York, NY",
      occupation: "Engineer",
      hobbies: "Reading, Hiking, Traveling",
      children: [
        {
          name: "Donna and Tom Bethge",
          birthday: "1965-05-12",
          location: "Los Angeles, CA",
          occupation: "Doctor",
          hobbies: "Running, Cooking",
          children: [
            {
              name: "Sam Bethge",
              birthday: "1990-08-20",
              location: "San Francisco, CA",
              occupation: "Designer",
              hobbies: "Drawing, Music",
              children: []
            },
            {
              name: "Louis Bethge",
              birthday: "1990-08-20",
              location: "San Francisco, CA",
              occupation: "Designer",
              hobbies: "Drawing, Music",
              children: []
            },
            {
              name: "Megan Bethge",
              birthday: "1992-07-14",
              location: "Boston, MA",
              occupation: "Teacher",
              hobbies: "Writing, Yoga",
              children: []
            }
          ]
        }
      ]
    };

    // Dimensions
    const width = window.innerWidth;
    const height = window.innerHeight;

    const root = d3.hierarchy(data);
    const treeLayout = d3.tree().size([width - 200, height - 200]);
    treeLayout(root);

    const svg = d3.select(svgRef.current)
      .attr('width', width)
      .attr('height', height);

    // Clear previous render
    svg.selectAll('*').remove();

    // Add a group element for zoom and pan
    const g = svg.append('g')
      .attr('transform', 'translate(100,100)'); // Initial translation

    let lastTransform = d3.zoomIdentity;

    // Smooth zoom and pan behavior with different transition speeds
    const zoom = d3.zoom()
      .scaleExtent([0.5, 3]) // Limit the zoom scale
      .on('zoom', (event) => {
        const currentTransform = event.transform;
        
        // Separate the scale (zoom) and translation (pan) components
        const scaleChanged = currentTransform.k !== lastTransform.k;
        const translateChanged = currentTransform.x !== lastTransform.x || currentTransform.y !== lastTransform.y;

        if (scaleChanged) {
          // Apply smooth transition for zoom
          g.transition().duration(100).attr('transform', `translate(${currentTransform.x},${currentTransform.y}) scale(${currentTransform.k})`);

          // Adjust the text size with slower transition during zoom
          g.selectAll('text')
            .transition().duration(100)
            .style('font-size', `${14 / currentTransform.k}px`);
        } else if (translateChanged) {
          // Apply faster transition for panning
          g.transition().duration(50).attr('transform', `translate(${currentTransform.x},${currentTransform.y}) scale(${currentTransform.k})`);
        }

        lastTransform = currentTransform;
      });

    svg.call(zoom);

    // Explicitly suppress double-click zoom
    svg.on('dblclick.zoom', null); // This should disable double-click zoom

    // Draw links (lines)
    g.selectAll('line')
      .data(root.links())
      .enter()
      .append('line')
      .attr('x1', d => d.source.x)
      .attr('y1', d => d.source.y)
      .attr('x2', d => d.target.x)
      .attr('y2', d => d.target.y)
      .attr('stroke', 'black');

    // Draw nodes (circles and text)
    const nodes = g.selectAll('g')
      .data(root.descendants())
      .enter()
      .append('g')
      .attr('transform', d => `translate(${d.x},${d.y})`);

    nodes.append('circle')
      .attr('r', 20)
      .attr('fill', 'steelblue')
      .on('click', (event, d) => {
        // Check if a tooltip already exists for this node
        const existingTooltip = g.select(`.tooltip-${d.data.name.replace(/\s+/g, '-')}`);
        
        if (!existingTooltip.empty()) {
          // If tooltip exists, remove it
          existingTooltip.remove();
        } else {
          // Otherwise, add new tooltip with all fields
          g.append('text')
            .attr('class', `tooltip tooltip-${d.data.name.replace(/\s+/g, '-')}`)
            .attr('x', d.x + 25)
            .attr('y', d.y + 5)
            .attr('text-anchor', 'start')
            .attr('font-size', '14px')
            .attr('fill', 'gray')
            .text(`Birthday: ${d.data.birthday}`)
            .append('tspan')
            .attr('x', d.x + 25)
            .attr('dy', '1.2em')
            .text(`Location: ${d.data.location}`)
            .append('tspan')
            .attr('x', d.x + 25)
            .attr('dy', '1.2em')
            .text(`Occupation: ${d.data.occupation}`)
            .append('tspan')
            .attr('x', d.x + 25)
            .attr('dy', '1.2em')
            .text(`Hobbies: ${d.data.hobbies}`);
        }
      });

    nodes.append('text')
      .attr('dy', -30)
      .attr('text-anchor', 'middle')
      .text(d => d.data.name)
      .style('font-size', '14px') // Base font size
      .style('fill', 'black');

  }, []);

  return (
    <div className="App">
      <svg ref={svgRef}></svg>
    </div>
  );
};

export default App;
